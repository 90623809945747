.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 0 5%;
  background-color: #ea3354;
}

.brand {
  font-size: 1rem;
  font-weight: bold;
}

.socialIcon {
  margin-left: 15px;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 100px;
  padding: 0 5%;
  background-color: #f0f4f9;
}

.appName {
  font-size: 2rem;
  font-weight: bold;
}

.routes {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  list-style-type: none;
}

.routes > li > a {
  margin-left: 15px;
  font-size: 1rem;
  font-weight: 600;
  color: black;
  text-decoration: none;
  text-transform: capitalize;
}

@media screen and (min-width: 750px) {
  .container {
    padding: 0 20%;
  }

  .navbar {
    padding: 0 20%;
  }
}
